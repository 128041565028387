<template>
	<div class="opinionBook">
		<div class="opinionBook-top"></div>
		<el-col :span="1" style="color: transparent">1</el-col>	
		<el-col :span="22" class="opinionBook-content">
			<div style="color: #1577CB;margin-bottom: 15px;font-size: 16px;">违法行为：</div>
			<div style="font-size: 14px;color: #505050;margin-bottom: 15px;line-height: 25px;">
			  {{consult.illegalAct}}
			</div>
			<div class="item-title">
				<el-tabs v-model="activeName">
          <el-tab-pane label="行政风险" name="politics">
            <div v-if="consult.adminTermsDetails.length < 1">
              <el-empty description="暂无风险"></el-empty>
            </div>
            <div v-else>
              <div style="color: #1577CB;font-size: 16px;">行政处罚依据：</div>	
              <div style="font-size: 14px;color: #505050;line-height: 25px;" v-for="(item,tIndex) in consult.adminTermsDetails" :key="'adminTermsDetails'+tIndex">
                <div class="fw-b">{{ item.legalBasis }}</div>
                <div>{{ item.regulations }}</div>
                <div :class="consult.adminTermsDetails.length > 1 ? 'mrt' : ''">{{ item.provisions }}</div>
              </div>
            </div>
					</el-tab-pane>
          <el-tab-pane label="刑事风险" name="penal">
            <div v-if="!consult.crimeCaseStandards">
              <el-empty description="暂无风险"></el-empty>
            </div>
            <div v-else>
              <div style="color: #1577CB;font-size: 16px;">可能构成犯罪的罪名：</div>
              <div style="font-size: 14px;color: #505050;line-height: 25px;">
                {{ consult.canOffenseNames }}
              </div>
              <el-divider></el-divider> 
              <div style="color: #1577CB;font-size: 16px;">刑事立案标准：</div>
              <div style="color: #505050;line-height: 25px;">
                {{ consult.crimeCaseStandards }}
              </div>
              <el-divider></el-divider> 
              <div style="color: #1577CB;font-size: 16px;">刑事处罚依据：</div>
              <div  v-for="(item,pIndex) in consult.crimePenalTermsDetails" :key="'crimePenalTermsDetails'+pIndex">
                <div style="color: #505050;line-height: 25px;" class="fw-b">
                  {{ item.legalBasis }}
                </div>
                <div style="color: #505050;line-height: 25px;">
                  {{ item.regulations }}
                </div>
                <div style="color: #505050;line-height: 25px;" :class="consult.crimePenalTermsDetails.length > 1 ? 'mrt' : ''">
                  {{ item.provisions }}
                </div>
              </div>
              <el-divider></el-divider> 
              <div style="color: #1577CB;font-size: 16px;" v-if="consult.crimeCaseTermsDetails.length > 0">刑事立案追诉标准：</div>
              <div v-for="(item,cIndex) in consult.crimeCaseTermsDetails" :key="'crimeCaseTermsDetails'+cIndex">
                <div style="color: #505050;line-height: 25px;" class="fw-b">
                  {{ item.legalBasis }}
                </div>
                <div style="color: #505050;line-height: 25px;">
                  {{ item.regulations }}
                </div>
                <div style="color: #505050;line-height: 25px;" :class="consult.crimeCaseTermsDetails.length > 1 ? 'mrt' : ''">
                  {{ item.provisions }}
                </div>
              </div>
            </div>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div class="topic-content">
			</div>
		</el-col>
		<el-col :span="1"></el-col>
		<div class="opinion-floor">
			Copyright ©2022-2025  Mask版权所有
		</div>
	</div>
</template>

<script>
	import { getPopinionDetails } from '../../api'; 
	export default {
		props:['planData','eventType'],
		data(){
			return {
				activeName: 'politics',
        consult: {
          adminTermsDetails: []
        }
			}
		},
		created() {
			this.activeName = this.eventType;
      getPopinionDetails({ id: this.planData.id }).then(({ data }) => {
        this.consult = data;
      });
		},
		methods: {
			handleClick() {
				this.$parent.$parent.$parent.$parent.showDialog = false;
			},
		}
	}
</script>

<style lang="less">
	.opinionBook {
		width: 100%;
		height: 100%;
		background: #EBF0FA;
		.opinionBook-top {
			width: 100%;
			height: 440px;
			background: url('../../assets/mask/bg-blue.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
		}
		.opinionBook-content {
			min-height: 800px;
			background-color: #fff;
			position: relative;
			z-index: 2;
			border-radius: 5px;
			margin-top: 80px;
			padding: 20px;
			.item-title {
				height: 45px;
				border-radius: 3px;
				line-height: 45px;
				padding-left: 15px;
				background-color: #DAB87A;

        .el-tabs__content::-webkit-scrollbar {
          width: 10px;
          background-color: #fff;
        }
        /*鼠标移动上去再显示滚动条*/
        .el-tabs__content:hover ::-webkit-scrollbar-track-piece {
          background-color: #fff;
          border-radius: 6px;
        }
        .el-tabs__content:hover::-webkit-scrollbar-thumb:hover {
          background-color: #cdd7e0;
        }
        .el-tabs__content:hover::-webkit-scrollbar-thumb:vertical {
          background-color: #cdd7e0;
          border-radius: 6px;
          outline: 2px solid #fff;
          outline-offset: -2px;
          border: 2px solid #fff;
        }
        .el-tabs__content{
          overflow-y: auto;
          max-height: 600px;
        }
				
				.el-tabs__nav-wrap::after{
					background-color: transparent;
				}
				.el-tabs__nav{
					margin-top: 7px;
					.el-tabs__active-bar{
						background-color: transparent;
					}
					.el-tabs__item{
						color: #fff;
						height: 30px;
						line-height: 30px;
						padding: 0 20px;
					}
					.is-active {
						color: #000;
						font-weight: 600;
						background: #fff;
						border-radius: 5px 5px 0 0;
					}
				}
			}
			
			.topic-content{
				padding-top: 15px;
				display: flex;
		
			}
		}
		.opinion-floor{
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #C8C8C8;
			position: absolute;
			bottom: 30px;
		}
    .mrt{
      margin-bottom: 20px;
    }
    .fw-b{
      font-weight: bold;
    }
	}
</style>
