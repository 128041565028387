<template>
	<div class="medical">
		<div class="medical-top" v-if="topImg"></div>
		<div class="topic-top" v-if="topImg">
			<div>刑事行政合规体检记录</div>
		</div>
		<el-col :span="1" style="color: transparent">1</el-col>	
		<el-col :span="22" class="medical-content" v-if="topImg">
      <el-row :gutter="10" class="list-title" v-if="list.length < 0">
        <el-empty description="暂无数据" style="margin-top: 50px;"></el-empty>
      </el-row>
      <div v-else>
        <el-row :gutter="10" class="list-title">
          <el-col :span="4">体检时间</el-col>
          <el-col :span="4">关联省份</el-col>
          <el-col :span="4">关联城市</el-col>
          <el-col :span="4">关联行政部门</el-col>
          <el-col :span="4">关联管理职能</el-col>
          <el-col :span="4">查看</el-col>
        </el-row>
        <div class="medicalRecord">
          <el-row 
            :gutter="10" 
            v-for="(item,index) in list" 
            :key="index"
            v-if="index < Count"
            class="topic-content"
          >
            <el-col :span="4">{{item.examTime}}</el-col>
            <el-col :span="4">{{item.provinceName}}</el-col>
            <el-col :span="4">{{item.cityName}}</el-col>
            <el-col :span="4">{{item.deptName}}</el-col>
            <el-col :span="4">{{item.dutyName}}</el-col>
            <el-col :span="4" class="topic-right">
              <el-button
                type="primary" 
                size="small" 
                @click="handleView(item)"
                icon="el-icon-view"
              >体检结果
              </el-button>
              <el-button
                type="warning" 
                size="small" 
                @click="handleDownload(item)"
                icon="el-icon-bottom"
              >报告下载
              </el-button>
            </el-col>
          </el-row>
          <div style="text-align: center;">
            <el-button type="text" @click="handleMore" v-if="CountBtn">点击加载更多</el-button>
          </div>
      </div>
      </div>
		</el-col>
		<el-col :span="1"></el-col>
		<custom-dialog
			:visible.sync="showDialog">
			<opinion :eventType.sync="eventType"
               :planData="physicaData"></opinion>
		</custom-dialog>
		<div class="opinion-floor">
			Copyright ©2022-2025  Mask版权所有
		</div>
	</div>
</template>

<script>
  import { mapGetters } from 'vuex'
	import opinion from './opinion'; 
  import { getPhysicalDetails, getDownloadReport } from '../../api'; 
	export default {
		props:['planData'],
		components: {
      opinion
		},
		data(){
			return {
				topImg: true,
				showDialog: false,
				leftBtn: true,
        physicaData: {},
				eventType: '',    
				listcCount: 10,
				Count: 10,
				CountBtn: true,
        list: [],
        userId: ''
			}
		},
		watch: {
      showDialog: function(e) {
        this.topImg = !e;
      },
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    created() {
      this.userId = this.userInfo.user_id;
      getPhysicalDetails({
        memberId: this.userId,
        size: this.Count
      }).then(({data}) => {
        this.list = data.records;
        this.listcCount = data.total;
        this.CountBtn = this.Count < data.total;
      });
    },
		methods: {
			handleClick() {
				this.$parent.$parent.$parent.$parent.showDialog = false;
			},
			handleMore() {
				this.Count += 10;
        if (this.list.length >= this.listcCount) {
          this.CountBtn = false;
          return;
        }
        getPhysicalDetails({
          memberId: this.userId,
          size: this.Count
        }).then(({data}) => {
          this.list = data.records;
          this.CountBtn = this.Count < data.total;
        });
			},
      handleView(item) {
        this.physicaData = item;
        this.eventType = 3;
        this.showDialog = true;
      },
      handleDownload(item) {
        getDownloadReport({ 
          examId: item.id, 
          flag: 1,
        }).then((res) => {
          console.log(res)
          const binaryData = [];
          binaryData.push(res);
          this.pdfUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
				  window.open(this.pdfUrl)
        });
      }
		}
	}
</script>

<style lang="less" scoped>
	.medical {
		width: 100%;
		height: 100%;
		background: #EBF0FA;
		.medical-top {
			width: 100%;
			height: 440px;
			background: url('../../assets/mask/bg-blue.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
		}
		.topic-top{
			text-align: center;
			font-size: 24px;
			color: #fff;
			padding-bottom: 15px;
			padding-top: 80px;
			position: relative;
			z-index: 2;
		}
		.medical-content {
			min-height: 800px;
			background-color: #fff;
			position: relative;
			z-index: 2;
			border-radius: 5px;
			padding: 20px;
			.list-title {
				height: 45px;
				border-radius: 3px;
				line-height: 45px;
				color: #1577CB;
				background-color: #F3F6FB;
        .el-col{
          text-align: center;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
			}
      .medicalRecord::-webkit-scrollbar {
        width: 10px;
        background-color: #fff;
      }
      /*鼠标移动上去再显示滚动条*/
      .medicalRecord:hover ::-webkit-scrollbar-track-piece {
        background-color: #fff;
        border-radius: 6px;
      }
      .medicalRecord:hover::-webkit-scrollbar-thumb:hover {
        background-color: #cdd7e0;
      }
      .medicalRecord:hover::-webkit-scrollbar-thumb:vertical {
        background-color: #cdd7e0;
        border-radius: 6px;
        outline: 2px solid #fff;
        outline-offset: -2px;
        border: 2px solid #fff;
      }
      .medicalRecord{
        height: 700px;
        overflow-y: auto;
        overflow-x: hidden;
      }
      .topic-right{
        text-align: center;
        .el-button--primary{
          background-color: #1577CB;
          border-color: #1577CB;
        }
      }	

			.topic-content{
				padding-top: 15px;
        font-size: 12px;
        .el-col{
          text-align: center;
        }
        .line-feed{
          text-align: center;
          display: -webkit-box;
          /* autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }		
		}
		.medical-floor{
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #C8C8C8;
			position: absolute;
			bottom: 30px;
		}
	}
</style>
