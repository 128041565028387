<template>
	<div class="criterion">
		<div class="box-top" v-if="topImg"></div>
		<div class="top-hurdle" v-if="topImg">
			<img src="../assets/mask/logo.png" style="width: 95px; height: 30px;margin-top: 10px;margin-left: 20px;" />
			<div class="left-text c-p">
				合规体检
			</div>
			<div class="right-text c-p" @click="handleSkip">
				公司介绍
			</div>
			<div class="exit">
				<el-button type="text" @click="handleExit">个人中心<i class="el-icon-arrow-right el-icon--right"></i></el-button>
			</div>
		</div>
    <div style="display: flex;justify-content: space-between;padding: 0 25px;padding-top:15px;" v-if="topImg">
      <div style="color: #fff;line-height: 32px;font-size: 16px;">Mask刑事行政合规体检器</div>
      <el-button class="Check" @click="handleCheck" size="small">体检记录</el-button>
    </div>
		<div class="box-center" v-if="topImg">
			<div class="center-left">
				<div class="box-tree">
					<div style="color: #1577CB;padding-bottom: 10px;font-size: 16px;">
						刑事行政合规入口
					</div>
					<el-row style="margin-bottom: 10px;">
						<el-col :span="12">
							<el-select 
                v-model="nationalId" 
                size="small" 
                filterable 
                clearable
                placeholder="国"
                @change="clickNational"
              >
								<el-option
									v-for="item in nationals"
									:key="item.code"
									:label="item.name"
									:value="item.code">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="12" style="padding-left: 10px;">
							<el-select 
                v-model="provinceId" 
                size="small" 
                filterable 
                clearable
                placeholder="省"
                @change="clickProvince"
              >
								<el-option
									v-for="item in provinces"
									:key="item.code"
									:label="item.name"
									:value="item.code">
								</el-option>
							</el-select>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-select 
                v-model="cityId" 
                filterable 
                clearable
                size="small" 
                @change="clickCity"
                placeholder="市"
              >
								<el-option
									v-for="item in citys"
									:key="item.code"
									:label="item.name"
									:value="item.code">
								</el-option>
							</el-select>
						</el-col>
						<el-col :span="12" style="padding-left: 10px;">
							<el-select 
                v-model="countyId" 
                filterable 
                clearable
                size="small" 
                @change="clickCounty"
                placeholder="区/县"
              >
								<el-option
									v-for="item in countys"
									:key="item.code"
									:label="item.name"
									:value="item.code">
								</el-option>
							</el-select>
						</el-col>
					</el-row>
          <el-divider></el-divider> 
					<el-input
            size="small"
            placeholder="请输入搜索"
            v-model="filterText">
          </el-input>
					<el-tree
            class="filter-tree"
            :data="TreeData"
            node-key="id"
            :props="treeOption"
            accordion
            :filter-node-method="filterNode"
            @node-click="handleNodeClick"
            ref="tree">
          </el-tree>
          <div style="text-align: center;">
            <el-button type="text" @click="handleMore" v-if="CountBtn">点击加载更多</el-button>
            <!-- <el-button type="text" disabled v-else>没有更多了</el-button> -->
          </div>
				</div>
			</div>
      <div class="container">
        <div style="color: #1577CB;padding-bottom: 5px;font-size: 16px;">
          主管部门的管理职能
        </div>
        <el-divider></el-divider> 
        
        <div class="right-card">
          <el-col :span="24" v-if="dutyList.length < 1">
            <el-empty description="暂无数据"></el-empty>
          </el-col>
          <el-col :span="24" v-else>
            <el-row>
              <el-col :span="4" 
                v-for="(item,index) in dutyList" 
                :key="index"
                class="card-item c-p">
                <el-card shadow="hover" 
                  body-style="background-color: #F3F6FB;padding: 0;text-align: center;line-height: 38px;"
                  :class="{'card-active': department.index == index}"
                ><div @click="handleSelect(item,index)" 
                  style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding: 0 10px;">
                  {{item.dutyName}}
                  </div> 
                </el-card>
              </el-col>
            </el-row>
          </el-col>

        </div>
        
        <div class="card-details">
          <div class="details-title" v-if="department.name !== ''">
            {{department.name}}
            <div class="title-border"></div>
          </div>
          <div style="padding-top: 10px;padding-bottom: 20px;line-height: 25px;">
            针对所选行为类型<br />
            为企业做合规体检，以尽早发现风险和预防风险
          </div>
          <el-button type="primary" class="next" @click="handleAnswer">
            <div>开始合规体检</div> <i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
        </div>
        <div class="customer-ser"></div>
      </div>
		</div>
		<custom-dialog
			:visible.sync="showDialog"
			:leftBtn.sync="leftBtn">
			<answer :planData="answerData"></answer>
		</custom-dialog>
		<custom-dialog
			:visible.sync="showDialog2"
      :leftBtn.sync="leftBtn">
			<medical :planData="medicalData"></medical>
		</custom-dialog>
		<div class="floor">
			Copyright ©2022-2025  Mask版权所有
		</div>
	</div>
</template>

<script>
	import answer from './components/answer'; 
	import medical from './components/medical'; 
	import { 
    getNational,
    getProvince, 
    getCity, 
    getCounty,
    getTree, 
    getDeptduty,
  } from '../api'; 

	export default {
    name: 'criterion',
    components: {
      answer,
      medical
    },
    data() {
			return {
        topImg: true,
				showDialog: false,
				showDialog2: false,
				leftBtn: true,
				CountBtn: true,
				answerData: {
          nationalId: '',
          provinceId: '',
          cityId: '',
          countyId: '',
          deptId: '',
          dutyId: '',
          deptName: '',
          dutyName: '',
        },
				medicalData: {},
				department: {name: '', index: null},
        input3: '',
				nationalId: '',   //国家
				nationals: [],
				provinceId: '',   //省份
				provinces: [],
				cityId: '',       //市级
				citys: [],
				countyId: '',     //区/县
				countys: [],
				treeSearch: '',
				TreeData: [],    //左边树选择
        treeOption:{
          label:'deptName',
          children:'deptDutyList'
        },
        filterText: '',
        pagerTree: {
          size: 0,
          nationalId: null,
          provinceId: null,
          cityId: null,
          countyId: null,
          nextPage: true,
        },
        pager: {
          size: 99999,
          nationalId: null,
          provinceId: null,
          cityId: null,
          countyId: null,
          deptId: null,
          nextPage: true,
        },
				dutyList: []
			}
		},
    watch: {
      showDialog: function(e) {
        this.topImg = !e;
      },
      showDialog2: function(e) {
        this.topImg = !e;
      },
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    created() {
      getNational({parentCode: '0', size: 999}).then(({data}) => {
        this.nationals = data;
        if(data.length > 0){
          this.nationalId = data[0].code;
          getProvince({parentCode: data[0].code, size: 999}).then(({ data }) => {
            this.provinces = data;
          });
          this.pagerTree.nationalId = data[0].code;
          this.pagerTree.provinceId = null;
          this.pagerTree.cityId = null;
          this.pagerTree.countyId = null;
          this.pagerTree.size = 0; 
          this.pagerTree.nextPage = true;
          this.handleSection(this.pagerTree);
        }
      });
    },
		methods: {
      filterNode(value, data) {
        if (!value) return true;
        return data.deptName.indexOf(value) !== -1;
      },
      clickNational(e) {
        if(e){
          getProvince({
            parentCode: e,
            size: 999
          }).then( ({data}) => {
            this.provinces = data;
          });
        } else {
          this.provinces = [];
          this.citys = [];
          this.countys = [];
          this.TreeData = [];
          this.provinceId = null;
          this.cityId = null;
          this.countyId = null;
          this.pagerTree.size = 0; 
          this.pagerTree.nextPage = true; 
        }
        this.pagerTree.nationalId = e;
        this.pagerTree.provinceId = null;
        this.pagerTree.cityId = null;
        this.pagerTree.countyId = null;
        this.pagerTree.size = 0; 
        this.pagerTree.nextPage = true;
        this.handleSection(this.pagerTree);
      },
      clickProvince(e) {
        if(e){
          getCity({
            parentCode: e,
            size: 999
          }).then( ({data}) => {
            this.citys = data;
          });
        } else {
          this.citys = [];
          this.countys = [];
          this.TreeData = [];
          this.cityId = null;
          this.countyId = null;
          this.pagerTree.size = 0; 
          this.pagerTree.nextPage = true; 
        }
        this.pagerTree.nationalId = this.nationalId;
        this.pagerTree.provinceId = e;
        this.pagerTree.cityId = null;
        this.pagerTree.countyId = null;
        this.pagerTree.size = 0; 
        this.pagerTree.nextPage = true;
        this.handleSection(this.pagerTree);
      },
      clickCity(e) {
        if(e){
          getCounty({
            parentCode: e,
            size: 999
          }).then( ({data}) => {
            this.countys = data;
          });
        } else {
          this.citys = [];
          this.countys = [];
          this.TreeData = [];
          this.cityId = null;
          this.countyId = null;
          this.pagerTree.size = 0; 
          this.pagerTree.nextPage = true; 
        }
        this.pagerTree.nationalId = this.nationalId;
        this.pagerTree.provinceId = this.provinceId;
        this.pagerTree.cityId = e;
        this.pagerTree.countyId = null;
        this.pagerTree.size = 0; 
        this.pagerTree.nextPage = true;
        this.handleSection(this.pagerTree);
      },
      clickCounty(e){
        this.pagerTree.nationalId = this.nationalId;
        this.pagerTree.provinceId = this.provinceId;
        this.pagerTree.cityId = this.cityId;
        this.pagerTree.countyId = e;
        this.pagerTree.size = 0; 
        this.pagerTree.nextPage = true;
        this.handleSection(this.pagerTree);
      },
      handleNodeClick(item, e) {
        this.pager.nationalId = this.nationalId;
        this.pager.provinceId = this.provinceId;
        this.pager.cityId = this.cityId;
        this.pager.countyId = this.countyId;
        this.pager.deptId = item.id;
        this.department.name = '';
        this.department.index = null;
        this.answerData.dutyId = '';
        this.answerData.deptName = item.deptName;
        this.handleDeptduty(this.pager);
      },
      //获取部门
      handleSection(pager) {
        this.pagerTree.size += 20; 
        if(!this.pagerTree.nextPage) {
          this.CountBtn = false;
          return;
        }
        getTree(pager).then(({ data }) => {
          this.TreeData = data.records;
          this.pagerTree.nextPage = this.pagerTree.size < data.total;
          this.CountBtn = this.pagerTree.size < data.total;
        });
      },
      //获取管理类别
      handleDeptduty(pager) {
        getDeptduty(pager).then(({ data }) => {
          this.dutyList = data.records;
          this.pager.nextPage = this.pager.size < data.total;
        });
      },
			handleMore() {
				this.handleSection(this.pagerTree);
			},
			handleSelect(item,index) {
				this.department.name = item.dutyName;
				this.department.index = index;
        this.answerData.deptId = item.deptId;
        this.answerData.dutyId = item.id;
        this.answerData.dutyName = item.dutyName;
			},
			handleSkip() {
				this.$router.push({path:'/firminfo'});
			},
			handleExit() {
        this.$router.push({path: '/personal'});
			},
			handleAnswer() {
        if(!this.answerData.dutyId){
          this.$message.error('请选择职能');
          return;
        }
        this.answerData.nationalId = this.nationalId;
        this.answerData.provinceId = this.provinceId;
        this.answerData.cityId = this.cityId;
        this.answerData.countyId = this.countyId;
				this.showDialog = true;
			},
			handleCheck() {
				this.showDialog2 = true;
			}
		}
	}
</script>

<style lang="less">
	.criterion{
    width: 100%;
		.box-top{
			width: 100%;
			height: 440px;
			background: url('../assets/mask/bg.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
			z-index: -1;
		}
		.top-hurdle{
      width: 100%;
			height: 50px;
			background: #444A59;
			display: flex;
			font-size: 15px;
			color: #cccacc;
			line-height: 50px;
      margin-bottom: 20px;
			
			.left-text{
        margin: 0 20px;
				color: #fff;
				border-bottom: 2px solid #DAB87A;
			}
			.exit{
        .el-button--text{
          color: #cccacc;
				}
				position: absolute;
				right: 5px;
			}
		}
    .Check:active,
    .Check {
      background: rgba(255, 255, 255, 0) ;
      color: #fff;
      border-color: #fff;
    }
		
		.box-center{
      display: flex;
			padding: 20px;
      height: 100%;
      .center-left{
        width: 300px;
        height: 100%;
        .box-tree {
          height: 714px;
          padding: 15px 20px;
          background: #fff;
          border-radius: 5px;
          .el-divider--horizontal{
            margin: 8px 0;
          }
          .filter-tree::-webkit-scrollbar {
            width: 10px;
            background-color: #fff;
          }
          /*鼠标移动上去再显示滚动条*/
          .filter-tree ::-webkit-scrollbar-track-piece {
            background-color: #fff;
            border-radius: 6px;
          }
          .filter-tree::-webkit-scrollbar-thumb:hover {
            background-color: #cdd7e0;
          }
          .filter-tree::-webkit-scrollbar-thumb:vertical {
            background-color: #cdd7e0;
            border-radius: 6px;
            outline: 2px solid #fff;
            outline-offset: -2px;
            border: 2px solid #fff;
          }
          .filter-tree{
            max-height: 570px;
            overflow-y: auto;
          }
        }
      }
      .right-card::-webkit-scrollbar {
        width: 10px;
        background-color: #fff;
      }
      /*鼠标移动上去再显示滚动条*/
      .right-card:hover ::-webkit-scrollbar-track-piece {
        background-color: #fff;
        border-radius: 6px;
      }
      .right-card:hover::-webkit-scrollbar-thumb:hover {
        background-color: #cdd7e0;
      }
      .right-card:hover::-webkit-scrollbar-thumb:vertical {
        background-color: #cdd7e0;
        border-radius: 6px;
        outline: 2px solid #fff;
        outline-offset: -2px;
        border: 2px solid #fff;
      }

      .container{
        padding: 15px 25px;
        box-sizing: border-box;
        background: #fff;
        border-radius: 5px;
        margin-left: 10px;
        width: 100%;
        height: 744px;
        position: relative;
        .customer-ser{
          position: absolute;
          right: 0;
          bottom: 10px;
          margin-right: 42px;
          background: url('../assets/mask/kh.png') no-repeat;
          background-size: 100% 100%;
          width: 299px;
			    height: 121px;
        }
      }

			.right-card{
				max-height: 360px;
				overflow-y: auto;
        
				.card-item {
					padding-right: 10px;
          margin-bottom: 10px;
				}
				.card-active {
					.el-card__body{
						background-color: #DAB87A !important;
            color: #fff;
					}
				}
					
			}
			.card-details{
				height: 100%;
				padding-top: 36px;
				.details-title{
					color: #505050;
					font-size: 20px;
					height: 60px;
					
					.title-border {
						width: 30px;
						height: 3px;
						margin-top: 15px;
						background-color: #1577CB;
					}
				}
				.next {
					width: 320px;
					background-color: #1577CB;
					border-color: #1577CB;
					span{
						width: 100%;
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}
			
		.floor{
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #C8C8C8;
			position: absolute;
			bottom: 10px;
			z-index: -1;
		}
	}
</style>

