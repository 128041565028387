<template>
	<div class="answer">
		<div class="answer-top" v-if="topImg"></div>
		<div class="answer-content" v-if="topImg">
      <div v-if="sum < 1" style="padding-top: 180px;">
        <div class="topic-box">
          <el-empty description="暂无题目"></el-empty>
        </div>
      </div>
      <div v-else>
        <div class="topic-top">
          <div>{{this.planData.deptName}} / {{this.planData.dutyName}}</div>
          <div>{{count}}/{{sum}}</div>
        </div>
        <div class="topic-box">
          <div style="color: #1577CB;">{{ question.questionName }}</div>
          <el-divider></el-divider> 
          <div class="topic-content">
            <el-radio-group v-model="itemId" @change="handleChange">
              <div v-for="(item,index) in question.questionItem" :key="index" style="padding-bottom: 30px;">
                <el-radio :label="item.id">{{item.itemName}}</el-radio>
              </div>
            </el-radio-group>
          </div>
          <div class="confirm">
            <el-button 
              type="warning" 
              @click="handleLastStep"
              v-if="count !== 1"
            >上一步</el-button>
            <el-button 
              type="primary" 
              @click="handleNotarize"
            >确认</el-button>
          </div>
        </div>
      </div>
		</div>
		<custom-dialog
			:visible.sync="showDialog"
			:leftBtn.sync="leftBtn">
			<opinion :planData="opinionData"></opinion>
		</custom-dialog>
		<div class="answer-floor">
			Copyright ©2022-2025  Mask版权所有
		</div>
	</div>
</template>

<script>
  import { getQuestion, getQuestionDetails, putResults } from '../../api'; 
	import opinion from './opinion'; 
	export default {
		props:['planData'],
		components: {
      opinion
		},
		data(){
			return {
        count: 1,
        sum: 1,
        topicId: [],
        question: {},
        questionId: '',
        itemId: '',
        topicResults: { results: [] },
				topImg: true,
				showDialog: false,
				leftBtn: false,
				opinionData: {},
				topics:{
					name: '市城市管理委员会 / 燃气' 
				},
			}
		},
		created(){
      this.planData.size = 999999;
      this.topicResults = { ...this.planData, results: [] };
      getQuestion(this.planData).then(({ data }) => {
        this.topicId = data.records;
        this.sum = this.topicId.length;
        if (this.topicId.length > 0) {
          getQuestionDetails({ id: data.records[0] }).then(({ data }) => {
            this.question = data;
          });
        }
      });
		},
    watch: {
      showDialog: function(e) {
        this.topImg = !e;
      },
    },
		methods: {
      handleChange(id) {
        this.itemId = id;
      },
      handleNotarize() {
        this.questionId = this.topicId[this.count - 1];
        if (this.topicResults.results[this.count - 1]) {
          if (this.topicResults.results[this.count - 1].itemId !== this.itemId) {
            //修改答案
            this.topicResults.results[this.count - 1].itemId = this.itemId;
            this.topicResults.results[this.count - 1].questionId = this.questionId;
            if (this.topicResults.results[this.count]) {
              this.itemId = this.topicResults.results[this.count].itemId;
            } else {
              this.itemId = '';
            }
          } else {
            if (this.topicResults.results[this.count]) {
              this.itemId = this.topicResults.results[this.count].itemId;
            } else {
              this.itemId = '';
            }
          }
        } else {
          //答题
          if (!this.itemId) {
            this.$message.error('请答题');
            return;
          }
          this.topicResults.results.push({
            questionId: this.questionId,
            itemId: this.itemId,
          });
          if (this.count !== this.sum) {
            this.itemId = '';
          }
        }
        if (this.count < this.sum) {
          getQuestionDetails({ id: this.topicId[this.count] })
            .then(({ data }) => {
              this.question = data;
              this.count++;
            });
        } else {
          const loading = this.$loading({
            lock: true,
            text: '提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          const data = JSON.stringify(this.topicResults);
          putResults(data).then(({ data }) => {
            this.$message.success('完成答题');
            this.opinionData = data;
            this.showDialog = true;
          })
          .finally(() => {
            loading.close();
          });
        }
      },
      handleLastStep() {
        this.count -= 2;
        getQuestionDetails({ id: this.topicId[this.count] }).then(({ data }) => {
          this.question = data;
          this.itemId = this.topicResults.results[this.count].itemId;
          this.count++;
        });
      },
		}
	}
</script>

<style lang="less" scoped>
	.answer {
		width: 100%;
		height: 100%;
		background: #EBF0FA;
		.answer-top {
			width: 100%;
			height: 440px;
			background: url('../../assets/mask/bg-blue.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
		}
		.answer-content {
			width: 850px;
			height: 600px;
			margin: 0 auto;
			position: relative;
			z-index: 2;
			.topic-top{
				display: flex;
				justify-content: space-between;
				font-size: 18px;
				color: #fff;
				padding-bottom: 15px;
				padding-top: 180px;
			}
			.topic-box{
				width: 810;
				background-color: #fff;
				border-radius: 5px;
				padding: 20px;
				
				.topic-content{
					padding-top: 30px;
					padding-left: 10px;
					min-height: 210px;
				}
				.confirm{
					display: flex;
					justify-content: flex-end;
					.el-button--primary{
						background-color: #1577CB;
						border-color: #1577CB;
						width: 120px;
						margin-right: 20px;
					}
					.el-button--warning {
						background-color: #DAB87A;
						border-color: #DAB87A;
						width: 120px;
					}
				}
			}
		}
    ::v-deep .el-radio__label {
      font-size: 14px;
      padding-left: 10px;
      width: inherit;
      white-space: normal !important;
      word-break: break-all !important;
      word-wrap: break-word !important;
      line-height: 20px !important;
    }   
		.answer-floor{
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #C8C8C8;
			position: absolute;
			bottom: 30px;
		}
	}
</style>
