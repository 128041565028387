<template>
	<div class="opinion">
		<div class="opinion-top" v-if="topImg"></div>
		<div class="go-back" v-if="topImg">
			<el-button type="text" icon="el-icon-arrow-left" @click="handleClick">重新体检</el-button>
		</div>
		<div class="topic-top" v-if="topImg && risk === 1">
			<div>法律咨询意见书</div>
		</div>
    <div v-if="topImg && risk === 1" class="btn-report">
      <el-row>
        <el-col :span="1" style="color: transparent">1</el-col>	
          <el-col :span="22" style="text-align:right">
            <el-button
              type="warning" 
              size="small" 
              @click="handleReportDownload"
              icon="el-icon-bottom"
            >报告下载
            </el-button>
        </el-col>	
        <el-col :span="1" style="color: transparent">1</el-col>	
      </el-row>
    </div>
    <div v-if="risk === 0" class="box-empty">
      <div style="text-align: center;">
        <div class="emptys">
          <div style="color:#1577CB;font-size:24px;text-align: center;">合规体检完成</div>
          <el-empty 
            description="企业暂无该类别的法律风险" 
            :image="require('../../assets/mask/wfx.png')" 
            :image-size="100">
          </el-empty>
        </div>
        <el-button 
          type="warning" 
          style="width: 160px;margin-top: 36px;"
          @click="handleClick">
          返回
        </el-button>
      </div>
    </div>
    <el-row v-else>
      <el-col :span="1" style="color: transparent">1</el-col>	
      <el-col :span="22" class="opinion-content" v-if="topImg">
        <div class="list-title">
          可给予行政处罚的违法行为
        </div>
        <div style="height: 620px;overflow:auto">
          <div v-for="(item,index) in topicDetails" 
               :key="index" 
               v-if="index < Count">
            <div class="topic-content">
              <div class="topic-left">
                {{ item.illegalAct }}
              </div>
              <div class="topic-right" v-if="item.adminTermsIds.length > 0 && (item.crimeCaseTermsIds.length > 0 || item.crimePenalTermsIds.length > 0)">
                <el-button 
                  v-if="item.adminTermsIds.length > 0"
                  type="warning" 
                  size="small" 
                  @click="handleView(1,item.id)"
                  icon="el-icon-view"
                >行政处罚依据</el-button>
                <el-button 
                  type="primary" 
                  size="small" 
                  @click="handleView(2,item.id)"
                >
                  <img src="../../assets/mask/xx.png" 
                    style="width: 14px; height: 14px;margin-bottom: -2px;"/>
                    存在刑事风险
                </el-button>
              </div>
              <div class="topic-right" v-else-if="item.adminTermsIds.length > 0 && (item.crimeCaseTermsIds.length == 0 && item.crimePenalTermsIds.length == 0)">
                <el-button 
                  v-if="item.adminTermsIds.length > 0"
                  type="warning" 
                  size="small" 
                  @click="handleView(1,item.id)"
                  icon="el-icon-view"
                >行政处罚依据</el-button>
              </div>
              <div class="topic-right" v-else>
                <el-button 
                  type="primary" 
                  size="small" 
                  @click="handleView(2,item.id)"
                >
                  <img src="../../assets/mask/xx.png" 
                    style="width: 14px; height: 14px;margin-bottom: -2px;"/>
                    存在刑事风险
                </el-button>
              </div>
            </div>
            <el-divider></el-divider> 
          </div>
          <div style="text-align: center;">
            <el-button type="text" @click="handleMore" v-if="CountBtn">点击加载更多</el-button>
            <!-- <el-button type="text" disabled v-else>没有更多了</el-button> -->
          </div>
        </div>
      </el-col>
      <el-col :span="1" style="color: transparent">1</el-col>
    </el-row>
		<custom-dialog
			:visible.sync="showDialog"
			:leftBtn.sync="leftBtn">
			<opinion-book :eventType.sync="eventType2"
                    :planData="detailsData" 
										></opinion-book>
		</custom-dialog>
		<div class="opinion-floor">
			Copyright ©2022-2025  Mask版权所有
		</div>
	</div>
</template>

<script>
	import opinionBook from './opinionBook';
	import { getPopinion, getDownloadReport } from '../../api'; 
	export default {
		props:['planData','eventType'],
		components: {
      opinionBook
		},
		data(){
			return {
        risk: 0,
        detailsData: {},
        topImg: true,
				showDialog: false,
				leftBtn: true,
				eventType2: '',    //行政：politics,刑事：penal,
				listcCount: 10,
        Count: 10,
				CountBtn: true,
				topicDetails: []
			}
		},
    watch: {
      showDialog: function(e) {
        this.topImg = !e;
      },
    },
    created() {
      if (this.planData.isAdminRisk === 1 || this.planData.isCrimeRisk === 1) {
        this.risk = 1;
        getPopinion({ 
          examId: this.planData.id, 
          size: this.Count 
        }).then(({ data }) => {
          this.topicDetails = data.records;
          this.listcCount = data.total;
          this.CountBtn = this.Count < data.total;
        });
      }
    },
		methods: {
      handleReportDownload() {
        getDownloadReport({ 
          examId: this.planData.id, 
          flag: 1,
        }).then((res) => {
          console.log(res)
          const binaryData = [];
          binaryData.push(res);
          this.pdfUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));
				  window.open(this.pdfUrl)
        });
      },
			handleClick() {
        if (this.eventType === 3) {
          this.$parent.$parent.showDialog = false;
        }else{
          this.$parent.$parent.$parent.$parent.showDialog = false;
        }
			},
			handleMore() {
				this.Count += 10;
        if (this.topicDetails.length >= this.listcCount) {
          this.CountBtn = false;
          return;
        }
        getPopinion({ 
          examId: this.planData.id, 
          size: this.Count 
        }).then(({ data }) => {
          this.topicDetails = data.records;
          this.CountBtn = this.Count < data.total;
        });
			},
			handleView(type,id) {
				if (type == 1) {
					this.eventType2 = 'politics';
				}else {
          this.eventType2 = 'penal';
				}
        this.detailsData.id = id;
				this.showDialog = true;
			},
		}
	}
</script>

<style lang="less" scoped>
	.opinion {
		width: 100%;
		height: 100%;
		background: #EBF0FA;
		.opinion-top {
			width: 100%;
			height: 440px;
			background: url('../../assets/mask/bg-blue.png') no-repeat;
			background-size: 100% 100%;
			position: absolute;
		}
		.go-back{
      width: 100%;
      display: inline-block;
      line-height: 50px;
      padding-left: 20px;
			position: absolute;
			z-index: 9;
			.el-button--text{
				color: #fff;
			}
		}
    .btn-report { 
			font-size: 24px;
			padding-bottom: 15px;
			position: relative;
			z-index: 2;
    }
		.topic-top{
			text-align: center;
			font-size: 24px;
			color: #fff;
			padding-bottom: 15px;
			padding-top: 80px;
			position: relative;
			z-index: 2;
		}
    .box-empty {
      position: relative;
      z-index: 3;
      display: flex;
      justify-content: center;
      padding-top: 240px;
      .emptys {
        width: 400px;
        height: 320px;
        background: #fff;
        border-radius: 50%;
        padding-top: 80px;
      }
    }
		.opinion-content {
			min-height: 600px;
			background-color: #fff;
			position: relative;
			z-index: 2;
			border-radius: 5px;
			padding: 20px;
			.list-title {
				height: 45px;
				border-radius: 3px;
				line-height: 45px;
				padding-left: 15px;
				color: #1577CB;
        font-size: 16px;
				background-color: #F3F6FB;
			}
			
			.topic-content{
				padding-top: 15px;
				display: flex;
				.topic-left {
					flex: 1;
					padding-right: 10px;
					font-size: 14px;
				}
				.topic-right{
					width: 260px;
					.el-button--primary{
						background-color: #1577CB;
						border-color: #1577CB;
						width: 125px;
					}
					.el-button--warning {
						background-color: #DAB87A;
						border-color: #DAB87A;
						width: 125px;
					}
				}
			}
		}
		.opinion-floor{
			width: 100%;
			text-align: center;
			font-size: 14px;
			color: #C8C8C8;
			position: absolute;
			bottom: 30px;
		}
	}
</style>
